import React from 'react'
import { I18n } from '@front/volcanion'
import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => (
  <>
    <TableCell size='small'>
      <TextHeader textAlign='center' label={I18n.t('order.id.alter')} />
    </TableCell>
  </>
)

export default React.memo(Header)
