import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent, Button } from '@mui/material'

import { DateField, TextField, MenuSelectorField, ConfirmationDialog, SingleOptionSelectorField } from '@front/squirtle'
import SuspensionDialog from '@abra/elements/Popup/Suspension'

const Title = ({ formattedSuspension, onClickRemoveSuspend, statusChange, isReadOnly }) =>
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <Box style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
      <Typography variant='h5'> {I18n.t('info.general')} </Typography>
      <Typography sx={{ fontSize: 'subtitle.fontSize', color: 'red', fontWeight: 600 }}>{formattedSuspension}</Typography>
    </Box>

    {!!formattedSuspension && (
      <Box style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        {!isReadOnly && <Button size='small' variant='outlined' onClick={() => statusChange('suspended')}>
          {I18n.t('suspend.stop.modify')}
        </Button>}
        <Button size='small' variant='outlined' onClick={onClickRemoveSuspend}>
          {I18n.t('suspend.stop.suspend')}
        </Button>
      </Box>
    )}
  </Box>

const Rows = ({ isManagingRecord, statusColor, statusChange, verifyClientId }) =>
  <Grid container spacing={2}>
    <Grid item xs={3}>
      <TextField name='info.user_client_id' label={I18n.t('driver.code')} debounceTime={250} disabled={!!isManagingRecord} afterUserChange={verifyClientId} />
    </Grid>
    <Grid item xs={3}>
      <TextField name='info.last_name' label={I18n.t('name.label', { count: 1 })} required />
    </Grid>
    <Grid item xs={3}>
      <TextField name='info.first_name' label={I18n.t('name.first_name', { count: 1 })} required />
    </Grid>
    <Grid item xs={3} hidden={!isManagingRecord}>
      <TextField name='score.average_rating' label={I18n.t('evaluation.note', { count: 1 })} disabled />
    </Grid>
    <Grid item xs={3} hidden={!isManagingRecord}>
      {!!isManagingRecord && <DateField
        name={'info.createdAt'}
        label={I18n.t('date.create')}
        inputFormat={'DD/MM/YYYY'}
        disabled
        disableOpenPicker
      />}
    </Grid>
    <Grid item xs={3} hidden={!isManagingRecord}>
      {!!isManagingRecord && <DateField
        name={'info.updatedAt'}
        label={I18n.t('date.updatedAt')}
        inputFormat={'DD/MM/YYYY HH:mm'}
        mask={'__/__/____ __:__'}
        disabled
        disableOpenPicker
      />}
    </Grid>
    <Grid item xs={2}>
      <MenuSelectorField
        label={I18n.t('status.label', { count: 1 })}
        name={'status'}
        options={_.compact([
          { label: I18n.t('status.active'), value: 'active' },
          { label: I18n.t('status.deleted'), value: 'deleted', disabled: !isManagingRecord },
          { label: I18n.t('status.suspended'), value: 'suspended', disabled: !isManagingRecord }
        ])}
        valueDecoratorProps={{
          sx: { WebkitTextFillColor: statusColor, fontWeight: 600 }
        }}
        selectFirst
        required
        afterChange={statusChange}
      />
    </Grid>
    <Grid item xs={2}>
      <SingleOptionSelectorField
        name={'driverinfo.can_call_booker'}
        label={I18n.t('call.all_client')}
        clearOnEmpty={false}
      />
    </Grid>
  </Grid>


const PrimarySection = props => {
  const {
    suspendedStateName,
    initialSuspendedValues,
    onConfirmRemoveSuspend
  } = props || {}
  return (<>
    <SuspensionDialog
      form_name='driver'
      enable_motive={true}
      suspendedStateName={suspendedStateName}
      initialValues={initialSuspendedValues}
    />
    <ConfirmationDialog
      name={'remove_driver_suspend'}
      onConfirm={onConfirmRemoveSuspend}
    />
    <StepLabel>
      <Title {...props} />
    </StepLabel>
    <StepContent >
      <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
        <Rows {...props} />
      </Box>
    </StepContent>
  </>)
}
export default React.memo(PrimarySection)
