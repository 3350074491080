import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'

import Callbacks from './callbacks'
import Aux from '../../Aux'

const withContainer = Component => props => {
  const [record_id] = hooks.useFormState('record_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const [status] = hooks.useFieldValues(['status'])
  const [, { openDialog }] = hooks.useDialogs()
  const suspendedStateName = 'suspended'
  const [, setSuspendedState] = hooks.useFormState(suspendedStateName)
  const [user] = hooks.useModel('user', [record_id], { single: true, populate: ['auth'] })
  const [modifyUser] = hooks.useModelFunction('user', 'modify')

  const formattedSuspension = Aux.parseStatus(user)
  const statusName = FormatUtils.getStatus(_.pick(_.get(user, 'auth'), ['active', 'deleted', 'suspendedUntil', 'suspendedSince']))

  const initialSuspendedValues = {
    name: statusName,
    motive: user?.auth?.motive,
    suspendedSince: user?.auth?.suspendedSince,
    suspendedUntil: user?.auth.suspendedUntil
  }

  const verifyClientId = useCallback(hooks.useRecordExist('user', 'info.user_client_id'), [])
  const statusChange = useCallback(Callbacks.statusChangeHandler(openDialog, setSuspendedState), [openDialog, setSuspendedState])
  const onClickRemoveSuspend = useCallback(Callbacks.onClickRemoveSuspendHandler(openDialog), [openDialog])
  const onConfirmRemoveSuspend = useCallback(Callbacks.onConfirmRemoveSuspendHandler(record_id, modifyUser), [record_id, modifyUser])

  const statusColor = useMemo(() => FormatUtils.getStatusColor(status), [status])

  const mergedProps = {
    statusName,
    statusColor,
    isManagingRecord,
    formattedSuspension,
    suspendedStateName,
    initialSuspendedValues,
    isReadOnly,
    statusChange,
    onClickRemoveSuspend,
    onConfirmRemoveSuspend,
    verifyClientId,
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
