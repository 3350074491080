import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import { isRequired } from '@front/squirtle/utils/validators'

import { TextField, DateField, MenuSelectorField, SingleOptionSelectorField } from '@front/squirtle/'

const Row1 = ({ isManagingRecord, ...props }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextField name={'client_id'} label={I18n.t('companyservice.code')} validate={isRequired} required />
    </Grid>
    <Grid item xs>
      <TextField name={'name'} label={I18n.t('name.label', { count: 1 })} validate={isRequired} required />
    </Grid>
    <Grid item xs>
      <TextField name={'contact.email'} label={I18n.t('email.label', { count: 1 })} validate={isRequired} required />
    </Grid>
    <Grid item xs>
      <TextField name={'contact.gsm'} label={I18n.t('phone.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('status.label', { count: 1 })}
        name={'status'}
        options={_.compact([
          { label: <Box color='green' fontWeight={500}> {I18n.t('status.active')}  </Box>, value: 'active' },
          { label: <Box color='orange' fontWeight={500}> {I18n.t('status.inactive')}  </Box>, value: 'inactive' },
          !!isManagingRecord && { label: <Box color='red' fontWeight={500}> {I18n.t('status.archive')}  </Box>, value: 'archive' },
        ])}
        selectFirst
        required
      />
    </Grid>
  </Grid>

const Row2 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <DateField
        name={'availableSince'}
        label={I18n.t('companyservice.available.since')}
        inputFormat={'DD/MM/YYYY'}
        mask={'__/__/____'}
        validate={isRequired}
        required
      />
    </Grid>
    <Grid item xs>
      <DateField
        name={'availableUntil'}
        label={I18n.t('companyservice.available.until')}
        inputFormat={'DD/MM/YYYY'}
        mask={'__/__/____'}
        disablePast
      />
    </Grid>
    <Grid item xs={5}>
      <TextField
        name={'comments.information'}
        label={I18n.t('info.label', { count: 1 })}
        alwaysInclude
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'exclude_heatmap'}
        label={I18n.t('heatmap.exclude_heatmap')}
        clearOnEmpty={false}
      />
    </Grid>
  </Grid>

const PrimarySection = ({ formattedSuspension, ...props }) => <>
  <StepLabel>
    <Box display='flex'>
      <Typography variant='h5'> {I18n.t('info.general')}</Typography>
      <Typography sx={{ ml: 1, mt: '5px', fontSize: 'subtitle2.fontSize', color: 'red' }}>{formattedSuspension}</Typography>
    </Box>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 3, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2}>
        <Row1 {...props} />
        <Row2 />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
