import React from 'react'
import { I18n } from '@front/volcanion'

import { ConfirmationDialog, DialogContent } from '@front/squirtle'
import ActiveJobs from './ActiveJobs'

const Dialogs = ({ resetPassword, onCallDriver, handleSubmit }) => (<>
  <ConfirmationDialog name={'confirm_reset_password'} onConfirm={resetPassword} />
  <ConfirmationDialog
    name={'confirm_call_driver'}
    onConfirm={onCallDriver}
    yesLabel={I18n.t('action.confirm')}
    noLabel={I18n.t('action.cancel')}
  />
  <ConfirmationDialog
    name={'show_active_jobs'}
    onConfirm={handleSubmit}
    yesLabel={I18n.t('action.confirm')}
    noLabel={I18n.t('action.cancel')}
    maxWidth={'xs'}
  >
    <DialogContent>
      <ActiveJobs />
    </DialogContent>
  </ConfirmationDialog>
</>
)

export default React.memo(Dialogs)
