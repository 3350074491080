import { I18n } from '@front/volcanion'
class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        client_id: _.get(record, 'client_id'),
        name: _.get(record, 'name'),
        contact: {
          email: _.get(record, 'contact.email'),
          address: _.get(record, 'contact.address'),
          gsm: _.get(record, 'contact.gsm'),
        },
        info: {
          nace: _.get(record, 'info.nace'),
          language: _.get(record, 'info.language')
        },
        exclude_heatmap: record?.exclude_heatmap
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      return {
        client_id: _.get(values, 'client_id'),
        name: _.get(values, 'name'),
        contact: {
          email: _.get(values, 'contact.email'),
          address: _.get(values, 'contact.address'),
          gsm: _.get(values, 'contact.gsm'),
        },
        info: {
          nace: _.get(values, 'info.nace'),
          language: _.get(values, 'info.language')
        },
        exclude_heatmap: values?.exclude_heatmap
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        tab: 1,
        title: _.get(record, 'client_id')
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }
  static getFailedNotificationHandler() {
    return function getFailedNotification(err, data, extra, meta, state) {
      const code = err.getCode()
      const context = err.getContext()
      if (code === 'RECORD_EXISTS' && _.get(context, 'key') === 'client_id') {
        return [I18n.t('error.already_exists.company_client_id'), { variant: 'error' }]
      }
    }
  }
}

export default Callbacks
