import React from 'react'
import { I18n } from '@front/volcanion/'

import { IconButton, Typography, Grid, Box, StepLabel, StepContent, Icon } from '@mui/material'
import { DateField, TextField, TextComponent, MenuSelectorField, SingleOptionSelectorField, ModelMenuSelectorField, ModelAutocompleteField, AddressAutocompleteField } from '@front/squirtle'

const Title = ({ titleFormatted, ...props }) =>
  <Box sx={{ display: 'flex', alignItems: 'center', fontSize: 'h6.fontSize', color: 'text.title' }}>
    <Typography variant='h5'> {I18n.t('contract.label', { count: 1 })}</Typography>
    <Typography sx={{ color: 'primary.main', fontWeight: 630, ml: 1 }}>
      {titleFormatted}
    </Typography>
  </Box>

const Row1 = ({
  payment_types,
  isReadOnly,
  handleClickEnableCustomPayment,
  enableCustomPayment,
  verifyClientId,
}) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={2}>
      <TextField name={'client_id'} label={I18n.t('contract.companycontract')} required debounceTime={250} afterUserChange={verifyClientId} />
    </Grid>
    <Grid item xs={2}>
      <TextField name={'name'} label={I18n.t('name.label', { count: 1 })} required />
    </Grid>
    <Grid item xs={3}>
      <TextField name={'contact.email'} label={I18n.t('email.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name={'formula'}
        label={I18n.t('commercial_formula.label', { count: 1 })}
        config={{ sort: ['name ASC'] }}
        model_name={'commercialformula'}
        labelKeys={['name']}
        loadOnMount
        searchMode={'search'}
        required
      />
    </Grid>
    <Grid item xs hidden={_.isEmpty(payment_types) && !enableCustomPayment}>
      <ModelAutocompleteField
        name={'payment_types'}
        label={I18n.t('bank.payment_methods')}
        model_name={'paymenttype'}
        searchMode={'search'}
        config={{ sort: ['display_index ASC'], forced_filter: { disabled: false } }}
        labelKeys={['name_translated']}
        multiple
        loadOnMount
      />
    </Grid>
    <Grid item xs hidden={!_.isEmpty(payment_types) || !!enableCustomPayment}>
      <TextComponent
        disabled
        label={I18n.t('bank.payment_methods')}
        value={'Selon formule commerciale'}
        InputProps={{
          endAdornment: <IconButton onClick={handleClickEnableCustomPayment} hidden={!!isReadOnly}>
            <Icon fontSize='small' color='primary'> {'edit'} </Icon>
          </IconButton>
        }}
      />
    </Grid>
  </Grid>

const Row2 = ({ isManagingRecord, handleStatusChange, handleDirectAccessChange, handleDisplayListChange, isReadOnly, ...props }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <DateField
        name={'availableSince'}
        label={I18n.t('contract.available.since')}
        inputFormat={'DD/MM/YYYY'}
        disableOpenPicker={!!isReadOnly}
        required
      />
    </Grid>
    <Grid item xs>
      <DateField
        name={'availableUntil'}
        label={I18n.t('contract.available.until')}
        inputFormat={'DD/MM/YYYY'}
        disableOpenPicker={!!isReadOnly}
        disablePast
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        label={I18n.t('status.label', { count: 1 })}
        name={'status'}
        options={_.compact([
          { label: <Box color='green' fontWeight={500}> {I18n.t('status.active')}  </Box>, value: 'active' },
          { label: <Box color='orange' fontWeight={500}> {I18n.t('status.inactive')}  </Box>, value: 'inactive' },
          !!isManagingRecord && { label: <Box color='red' fontWeight={500}> {I18n.t('status.archive')}  </Box>, value: 'archive' },
        ])}
        selectFirst
        allowNone={false}
        afterUserChange={handleStatusChange}
      />
    </Grid>
    <Grid item xs>
      <MenuSelectorField
        name={'ivr_access'}
        label={I18n.t('ivr_access.label', { count: 1 })}
        options={[
          { label: I18n.t('ivr_access.never'), value: '0' },
          { label: I18n.t('ivr_access.yes_if_order'), value: '1' },
          { label: I18n.t('ivr_access.yes_without_limit'), value: '2' },
          { label: I18n.t('commercial_formula.label.short'), value: null }
        ]}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'direct_access'}
        label={I18n.t('contract.access.label.direct')}
        afterChange={handleDirectAccessChange}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'display_list'}
        label={I18n.t('contract.display_service_list')}
        afterChange={handleDisplayListChange}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'auth_account'}
        label={I18n.t('bank.deferred_billing')}
        clearOnEmpty={false}
      />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'allow_auto_subscription'}
        label={I18n.t('contract.allow_auto_subscription')}
        clearOnEmpty={false}
      />
    </Grid>
  </Grid>

const Row3 = ({ onAddressResolve }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={4} hidden={true}>
      <MenuSelectorField
        name={'invoice_type'}
        label={I18n.t('email.label.billing')}
        options={[
          { label: I18n.t('company.mode.company'), value: 'company' },
          { label: I18n.t('company.mode.contract'), value: 'contract' },
          { label: I18n.t('company.mode.do'), value: 'do' }]}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField name='contact.address' label={I18n.t('address.billing')} />
    </Grid>
    <Grid item xs>
      <AddressAutocompleteField
        name={'address'}
        label={I18n.t('address.address_home')}
        noOptionsText={I18n.t('no_result')}
        resolveAddress
        onAddressResolve={onAddressResolve}
      />
    </Grid>
  </Grid>

const Row4 = props =>
  <Grid container item xs={12}>
    <Grid item xs>
      <TextField name='comment.text' label={I18n.t('comment.admin')} />
    </Grid>
    <Grid item xs>
      <SingleOptionSelectorField
        name={'exclude_heatmap'}
        label={I18n.t('heatmap.exclude_heatmap')}
        clearOnEmpty={false}
      />
    </Grid>
  </Grid>

const PrimarySection = props => <>
  <StepLabel>
    <Title {...props} />
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={2} >
        <Row1 {...props} />
        <Row2 {...props} />
        <Row3 {...props} />
        <Row4 />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
