import { I18n } from '@front/volcanion'

class Callbacks {
  static statusChangeHandler(openDialog, setSuspendedState) {
    return async function statusChange(value) {
      if (value === 'suspended') {
        openDialog('suspended_driver', { title: I18n.t('suspend.driver.title') })
      }
      else
        setSuspendedState({ name: value })
    }
  }

  static onClickRemoveSuspendHandler(openDialog) {
    return async function onClickRemoveSuspend() {
      openDialog('remove_driver_suspend', { title: I18n.t('suspend.stop.suspend'), description: I18n.t('suspend.stop.confirm') })
    }
  }

  static onConfirmRemoveSuspendHandler(user_id, userModify) {
    return async function onConfirmRemoveSuspend() {
      await userModify({ filter: { user_id }, updates: { type: 'driver', status: { name: 'active' } } })
    }
  }

}

export default Callbacks
