import FormatUtils from '@front/squirtle/utils/format'
import moment from 'moment'

const loadAuthenticationType = authentication_type => ({
  display_list: _.includes(['service'], authentication_type),
  direct_access: _.includes(['direct'], authentication_type)
})

const loadStatus = record => {
  if (record.deleted) return 'archive'
  else if (record.active) return 'active'
  else return 'inactive'
}

const exportStatus = status => ({
  active: _.includes(['active'], status),
  deleted: _.includes(['archive'], status)
})

const exportDate = (dateValue, timeValue) =>
  !!dateValue ? moment(dateValue, 'DD/MM/YYYY HH:mm').set(timeValue).toISOString(true) : undefined

const exportAuthenticationType = (direct_access, display_list) => {
  if (!direct_access && !display_list) return 'contract'
  else if (!direct_access && !!display_list) return 'service'
  else return 'direct'
}

const parseStatus = record => {
  const statusName = FormatUtils.getStatus(_.pick(_.get(record, 'auth'), ['active', 'deleted', 'suspendedUntil', 'suspendedSince']))
  const formattedSuspension = FormatUtils.formatSuspended(
    { name: statusName, ..._.pick(_.get(record, 'auth'), ['suspendedSince', 'suspendedUntil', 'motive']) },
    'driver'
  )
  return formattedSuspension
}


const Aux = {
  loadAuthenticationType,
  loadStatus,
  exportStatus,
  parseStatus,
  exportDate,
  exportAuthenticationType
}

export default Aux
