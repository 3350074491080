import React, { useCallback } from 'react'
import { FormTable, TableHeader, TableRow } from '@front/squirtle'

import Header from './Header'
import Row from './Row'

const ActiveJobs = ({ job_ids, job_watcher }) =>
  <FormTable
    model_name={'job'}
    loadOnMount={false}
    config={{
      populate: ['first_step.order'],
      initial_filter: {},
      forced_filter: {
        job_id: job_ids,
      },
      validate_filter: useCallback(() => !_.isEmpty(job_ids), [job_watcher]),
      watchers: [job_watcher],
    }}
    tableProps={{
      enablePagination: false,
      onRowClick: null,
    }}
  >
    <TableHeader><Header /></TableHeader>
    <TableRow><Row /></TableRow>
  </FormTable>

export default ActiveJobs
