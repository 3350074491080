import React from 'react'

const withContainer = Component => ({ job_ids }) => {
  const job_watcher = _.flatten([job_ids]).join(',')
  const mergedProps = {
    job_ids,
    job_watcher,
  }
  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
