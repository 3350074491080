import React from 'react'

import { OrderLink } from '@abra/elements'
import { TableCell } from '@mui/material'

const Row = ({ row }) => (
  <>
    <TableCell size='small' padding='none'>
      <OrderLink record_id={row?.first_step?.order?.order_id} fontSize={13} />
    </TableCell>
  </>
)

export default React.memo(Row)
