
import React from 'react'

import { hooks } from '@front/volcanion/'

const withContainer = Component => props => {
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [formattedSuspension] = hooks.useFormState('formattedSuspension')

  const mergedProps = {
    isManagingRecord,
    formattedSuspension
  }

  return <Component {...mergedProps} />
}

export default withContainer
